<template>
	<!--서비스신청 페이지: 비회원-->
	<div class="container--center" v-if="computedValue === `ko`">
		<!--서비스신청 폼-->
		<div class="form--large">
			<!--이름-->
			<div class="form__group">
				<label for="input-1">* 이름</label>
				<input
					id="input-1"
					v-model="inquiry.userName"
					placeholder="이름(필수)"
					type="text"
					maxlength="20"
				/>
			</div>
			<!--회사 이름-->
			<div class="form__group">
				<label for="input-2">* 회사 이름</label>
				<input
					id="input-2"
					v-model="inquiry.userCompany"
					placeholder="회사 이름(필수)"
					type="text"
					maxlength="100"
				/>
			</div>
			<!--이메일-->
			<div class="form__group">
				<label for="input-3">* 이메일</label>
				<input
					id="input-3"
					v-model="inquiry.userEmail"
					placeholder="이메일(필수)"
					type="text"
					maxlength="60"
				/>
			</div>
			<!--핸드폰 번호-->
			<div class="form__group">
				<label for="input-4">* 핸드폰 번호</label>
				<input
					id="input-4"
					v-model="inquiry.userPhone"
					placeholder="핸드폰 번호(필수)"
					type="text"
					maxlength="11"
					oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')"
				/>
			</div>
			<!--문의 제목-->
			<!-- <div class="form__group">
				<label for="input-5">* 문의 제목</label>
				<input
					id="input-5"
					v-model="inquiry.title"
					placeholder="문의 제목(필수)"
					type="text"
					maxlength="200"
				/>
			</div> -->
			<!--문의 구분-->
			<div class="form__group">
				<label for="input-6">* 문의 구분</label>
				<select v-model="inquiry.selected" name="inquiry" id="input-6">
					<option value="">문의 구분(필수)</option>
					<option value="productName">취약점 분석 신청</option>
					<option value="svUse">컨설팅 신청</option>
				</select>
			</div>
			<!--문의 내용-->
			<!-- <div class="form__group">
				<label for="input-7">* 문의 내용</label>
				<textarea
					class="inquiry_textarea"
					v-model="inquiry.contents"
					id="input-7"
					placeholder="문의 내용(필수)"
					type="text"
					maxlength="500"
				/>
			</div> -->
			<!--동의하기-->
			<div class="agree__group">
				<input type="checkbox" id="checkbox-1" v-model="inquiry.agree" />
				<router-link to="/terms" target="_blank">이용약관</router-link>
				<span> &nbsp;및&nbsp;</span>
				<router-link to="/privacy" target="_blank">
					개인정보 처리방침
				</router-link>
				<span>&nbsp;에 동의합니다.</span>
			</div>
			<!--서비스신청 제출 버튼-->
			<button
				type="submit"
				class="btn__form"
				:class="{ btnDisabled: !allCheck }"
				:disabled="!allCheck"
				@click="submitForm()"
			>
				서비스신청
			</button>
		</div>
	</div>
	<div class="container--center" v-else-if="computedValue === `en`">
		<!--서비스신청 폼-->
		<div class="form--large">
			<!--이름-->
			<div class="form__group">
				<label for="input-1">* Name</label>
				<input
					id="input-1"
					v-model="inquiry.userName"
					placeholder="Name(required)"
					type="text"
					maxlength="20"
				/>
			</div>
			<!--회사 이름-->
			<div class="form__group">
				<label for="input-2">* Company Name</label>
				<input
					id="input-2"
					v-model="inquiry.userCompany"
					placeholder="Company Name(required)"
					type="text"
					maxlength="100"
				/>
			</div>
			<!--이메일-->
			<div class="form__group">
				<label for="input-3">* Email</label>
				<input
					id="input-3"
					v-model="inquiry.userEmail"
					placeholder="Email(required)"
					type="text"
					maxlength="60"
				/>
			</div>
			<!--핸드폰 번호-->
			<div class="form__group">
				<label for="input-4">* Phone Number</label>
				<input
					id="input-4"
					v-model="inquiry.userPhone"
					placeholder="Phone Number(required)"
					type="text"
					maxlength="11"
					oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')"
				/>
			</div>
			<!--문의 제목-->
			<!-- <div class="form__group">
				<label for="input-5">* Inquiry Subject</label>
				<input
					id="input-5"
					v-model="inquiry.title"
					placeholder="Inquiry Subject(required)"
					type="text"
					maxlength="200"
				/>
			</div> -->
			<!--문의 구분-->
			<div class="form__group">
				<label for="input-6">* Inquiry Type</label>
				<select v-model="inquiry.selected" name="inquiry" id="input-6">
					<option value="">Inquiry Type(required)</option>
					<option value="productName">
						Vulnerability Assessment Application
					</option>
					<option value="svUse">Consulting Application</option>
					<!-- <option value="repair">Management / Maintenance</option>
					<option value="buyIt">Purchase / Cost</option>
					<option value="cloud">Cloud</option>
					<option value="server">Server</option>
					<option value="connect">Integration</option>
					<option value="error">Error / Report</option>
					<option value="member">Member Information</option>
					<option value="etc">Other</option> -->
				</select>
			</div>
			<!--서비스신청 내용-->
			<!-- <div class="form__group">
				<label for="input-7">* Inquiry Content</label>
				<textarea
					class="inquiry_textarea"
					v-model="inquiry.contents"
					id="input-7"
					placeholder="Inquiry Content(required)"
					type="text"
					maxlength="500"
				/>
			</div> -->
			<!--동의하기-->
			<div class="agree__group">
				<input type="checkbox" id="checkbox-1" v-model="inquiry.agree" />
				<span> &nbsp;I agree to the&nbsp;</span>
				<router-link to="/terms" target="_blank">Terms of Service</router-link>
				<span> &nbsp;and&nbsp;</span>
				<router-link to="/privacy" target="_blank">
					Privacy Policy.
				</router-link>
			</div>
			<!--서비스신청 제출 버튼-->
			<button
				type="submit"
				class="btn__form"
				:class="{ btnDisabled: !allCheck }"
				:disabled="!allCheck"
				@click="submitForm()"
			>
				Service Application
			</button>
		</div>
	</div>
</template>

<script>
import { validateEmail } from '@/utils/validation';
import InquiryService from '../../services/inquiryService';

export default {
	data() {
		return {
			inquiry: {
				userName: '',
				userCompany: '',
				userEmail: '',
				userPhone: '',
				// title: '',
				// contents: '',
				// 서비스신청 구분 선택된 항목
				selected: '',
				// 동의 여부
				agree: false,
			},
		};
	},
	computed: {
		/* 서비스신청 폼 유효성 검사 */
		// 이메일 검사
		emailState() {
			if (this.inquiry.userEmail != '') {
				return validateEmail(this.inquiry.userEmail);
			} else {
				return null;
			}
		},
		// 서비스신청 버튼 활성화 검사(서비스신청 필수항목 입력 완료 시 버튼색 변경 및 활성화)
		allCheck() {
			if (
				this.inquiry.userName &&
				this.inquiry.userCompany &&
				this.emailState == true &&
				this.inquiry.userPhone &&
				// this.inquiry.title &&
				// this.inquiry.contents &&
				this.inquiry.selected &&
				this.inquiry.agree == true
			) {
				return true;
			} else {
				return false;
			}
		},
		computedValue() {
			return this.$store.state.language;
		},
	},
	methods: {
		submitForm() {
			this.$router.push('./inquiry/complete');
			const formData = {
				userName: this.inquiry.userName,
				userCompany: this.inquiry.userCompany,
				userEmail: this.inquiry.userEmail,
				userPhone: this.inquiry.userPhone,
				// title: this.inquiry.title,
				// contents: this.inquiry.contents,
				selected: this.inquiry.selected,
			};

			InquiryService.inquirySend(formData)
				.then(res => {
					if (res.data.message == 'SUCCESS') {
						alert('문의가 완료되었습니다.');
						console.log('서비스신청 폼 전송');
					}
				})
				.catch(err => {
					alert(err);
				});
		},
	},
};
</script>

<style></style>
